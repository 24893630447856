const Footer = () => {
    return ( 
        <div className="row bg-warning">
            <div className="col-sm-12 text-center"> {/* making text centered, it shows only one in line */}
            <b> &copy; RealGrande 2024</b>
            </div>
        </div>
     );
}
 
export default Footer;